import styled from 'styled-components';

import { media } from 'styles/mixins';

const Image = styled.img`
  width: 13.75rem;
  margin: 0 auto;
  display: block;

  ${media.mobile`
    display: none;
  `};
`;

const GIF = styled(Image)`
  display: none;

  ${media.mobile`
    display: block;
  `};
`;

const Container = styled.div`
  padding: 0 var(--container-padding);
  scrollbar-width: none;
  overflow-x: auto;
  width: 100%;

  ${media.tablet`
    padding-left: var(--container-padding-tablet);
    padding-right: var(--container-padding-tablet);
  `}

  ${media.mobile`
    overflow-x: hidden;
    padding: 0 var(--container-padding-mobile);
  `}

  &::-webkit-scrollbar {
    display: none;
  }

  .swiper-wrapper {
    grid-auto-flow: column;
    column-gap: var(--gap);
    width: fit-content;
    margin: 0 auto;
    display: grid;

    ${media.mobile`
      column-gap: unset;
    `}
  }

  .swiper-slide {
    border-radius: var(--card-radius);
    padding: 3rem var(--gap) 3rem;
    row-gap: var(--gap);
    max-width: 19.2rem;
    display: grid;
    height: auto;

    ${media.mobile`
      max-width: unset;
      padding-top: calc(4rem + var(--progress-bar-height));
      height: 100%;
    `}

    // TODO: temproray solution
    &:last-of-type {
      display: none;
    }

    &:hover {
      ${Image} {
        display: none;
      }

      ${GIF} {
        display: block;
      }
    }

    div {
      display: grid;
      row-gap: 0.5rem;

      span {
        font-size: 1.125rem;
        line-height: 1.4375rem;
        color: var(--color-black-90);
        font-weight: var(--weight-bold);
      }

      p {
        font-size: 0.875rem;
        line-height: 1.3125rem;
        color: var(--color-black-90);
      }
    }
  }

  .swiper-pagination-progressbar {
    &.swiper-pagination-horizontal {
      background: var(--color-black-10);
      width: calc(100% - var(--gap) * 2);
      border-radius: var(--border-radius);
      height: var(--progress-bar-height);
      overflow: hidden;
      left: var(--gap);
      top: var(--gap);
    }

    .swiper-pagination-progressbar-fill {
      background: var(--color-black-90);
    }
  }
`;

export { Image, GIF, Container };
