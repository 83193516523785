import styled from 'styled-components';

import Checkmark from 'assets/checkmark-icon.svg';

import { easeOutTiming } from 'styles/animation';

const Wrapper = styled.div`
  grid-auto-columns: min-content;
`;

const Card = styled.li`
  transition: all ${easeOutTiming};
  border-radius: calc(var(--card-radius) / 2);
  border: var(--border) solid transparent;
  border-color: var(--border-color-10);
  min-width: 15.5rem;
  padding: 1.5rem;
  display: grid;

  &:hover {
    background-color: var(--color-black-03);
  }

  img {
    margin: 1rem 0;
    max-width: 100%;
  }
`;

const Scale = styled.div`
  grid-auto-flow: column;
  align-items: baseline;
  display: grid;

  > div {
    grid-auto-flow: column;
    justify-content: start;
    align-items: baseline;
    column-gap: 0.5rem;
    display: grid;
  }
`;

const ProgressBar = styled.div`
  transition: background-color ${easeOutTiming};
  background-color: var(--secondary-button-background-color);
  border-radius: var(--border-radius);
  height: var(--progress-bar-height);
  margin-top: 0.375rem;
  position: relative;
  overflow: hidden;

  span {
    background-color: ${({ isRaised }) =>
      isRaised ? 'var(--success-color)' : ' var(--primary-color)'};
    transform-origin: left top;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
`;

const Current = styled.p`
  margin-bottom: unset;
`;

const Target = styled.span`
  transition: color ${easeOutTiming};
  color: var(--secondary-text-color);
  font-weight: var(--weight-medium);
  font-size: 0.875rem;
  display: block;
`;

const Raised = styled(Checkmark)`
  margin-left: auto;
  height: 0.4375rem;
  width: 0.625rem;
`;

const Subtitle = styled.p`
  font-weight: var(--weight-medium);
  transition: color ${easeOutTiming};
  color: var(--secondary-text-color);
  line-height: 1.1875rem;
  margin: 1rem 0 0.5rem;
`;

const Description = styled.p`
  color: var(--secondary-text-color);
  transition: color ${easeOutTiming};
  font-size: 0.75rem;
`;

const Buttons = styled.div`
  grid-auto-flow: column;
  column-gap: 0.75rem;
  align-items: end;
  display: grid;
`;

export {
  Wrapper,
  Card,
  Scale,
  ProgressBar,
  Current,
  Target,
  Raised,
  Subtitle,
  Description,
  Buttons,
};
