import styled from 'styled-components';

import { HeadingBig, SubHeading } from 'components/UI/Headings';

import { media } from 'styles/mixins';

const Wrapper = styled.div`
  width: var(--form-width);

  ${media.mobile`
    width: auto;
  `}

  ${HeadingBig} {
    margin-bottom: 1rem;

    ${media.mobile`
      text-align: center;
    `}
  }

  ${SubHeading} {
    width: 90%;
    margin-bottom: 2rem;

    ${media.mobile`
      text-align: center;
    `}
  }
`;

const Amount = styled.div`
  grid-auto-flow: column;
  margin-bottom: 0.75rem;
  column-gap: 0.5rem;
  display: grid;

  > * {
    width: auto;
  }
`;

const Pay = styled.div`
  margin-bottom: 1.5rem;
`;

const Text = styled.span`
  font-size: 0.875rem;
  line-height: 1.025rem;
  margin-bottom: 1.5rem;
`;

const Partners = styled.div`
  margin-top: 4rem;

  p {
    font-size: 0.75rem;
    line-height: 0.875rem;
    margin-bottom: 0.75rem;
    font-weight: var(--weight-medium);
  }

  ${media.mobile`
    display: none;
  `}
`;

const Partner = styled.div`
  &:not(:last-of-type) {
    margin-right: 1.5rem;
  }
`;

export { Wrapper, Amount, Pay, Text, Partners, Partner };
