import { StructuredText } from 'react-datocms';

import HelpRequest from 'components/HelpRequest';

import { HeadingMedium, HeadingSmall } from 'components/UI/Headings';
import { ScrollableContainer } from 'components/UI/Containers';
import {
  ReverseButton,
  // SecondaryButton,
  PrimaryButton,
} from 'components/UI/Buttons';

import {
  Wrapper,
  Card,
  Scale,
  ProgressBar,
  Current,
  Target,
  Raised,
  Subtitle,
  Description,
  Buttons,
} from './styles';

const MainProjects = ({
  heading,
  projects,
  request,
  translations: { donate, viewResults },
}) => (
  <>
    <HeadingMedium isCentered as="h3">
      {heading}
    </HeadingMedium>
    <Wrapper as={ScrollableContainer}>
      <HelpRequest content={request} />
      {projects.edges.map((project, index) => {
        const {
          title,
          description,
          current,
          target,
          projectStatus,
          currency,
          linkToFacebookPost,
          gallery,
        } = project.node;
        const result = current / target;
        const isRaised = projectStatus !== 'Open';

        return (
          <Card key={index}>
            <div>
              <Scale>
                <div>
                  {/* eslint-disable react/jsx-one-expression-per-line */}
                  <Current as={HeadingSmall}>
                    {currency}
                    {current.toLocaleString()}
                  </Current>
                  <Target>
                    / {currency}
                    {target.toLocaleString()}
                  </Target>
                </div>
                {isRaised && <Raised />}
              </Scale>
              <ProgressBar isRaised={isRaised && true}>
                <span
                  style={{ transform: `scaleX(${isRaised ? target : result})` }}
                />
              </ProgressBar>
              <Subtitle>{title}</Subtitle>
              <Description>
                <StructuredText data={description} />
              </Description>
              {gallery[0]?.url && <img src={gallery[0]?.url} alt={title} />}
            </div>
            <Buttons>
              {!isRaised ? (
                <>
                  <PrimaryButton
                    as="a"
                    href={linkToFacebookPost}
                    rel="noopener noreferrer"
                    target="_blank"
                    isSmall
                  >
                    {donate}
                  </PrimaryButton>
                  {/* <SecondaryButton isSmall>{readDetails}</SecondaryButton> */}
                </>
              ) : (
                <ReverseButton
                  as="a"
                  href={linkToFacebookPost}
                  rel="noopener noreferrer"
                  target="_blank"
                  isWide
                  isSmall
                >
                  {viewResults}
                </ReverseButton>
              )}
            </Buttons>
          </Card>
        );
      })}
    </Wrapper>
  </>
);

export default MainProjects;
