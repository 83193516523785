// import checkout from 'ipsp-js-sdk';
// import { useForm } from 'react-hook-form';
// import { useEffect, useState } from 'react';

// import { CURRENCY_MAP, AMOUNT_LIST } from 'constants';

import { PrimaryButton, SecondaryButton } from 'components/UI/Buttons';
import { HeadingBig, SubHeading } from 'components/UI/Headings';
import { SubmitButtons } from 'components/UI/Form';
// import { InputField } from 'components/UI/Input';

import {
  Wrapper,
  // Amount,
  // Pay,
  // Text,
  // Partners,
  // Partner,
} from './styles';

const PaymentForm = ({ title, subTitle, translations }) => (
  <Wrapper>
    <HeadingBig>{title}</HeadingBig>
    <SubHeading>{subTitle}</SubHeading>
    {/* <Form onSubmit={handleSubmit()} className="checkout-form"> */}
    {/* <Amount>
        {AMOUNT_LIST.map((item, i) =>
          item.value !== '' ? (
            <SecondaryButton
              key={i}
              as="span"
              isActive={item.value === predefinedValue}
              onClick={() => setPredefinedValue(item.value)}
            >
              {CURRENCY_MAP[pageLocale].sign}
              {item.title}
            </SecondaryButton>
          ) : (
            <SecondaryButton
              key={i}
              as="span"
              isActive={item.value === predefinedValue}
              onClick={() => setPredefinedValue(item.value)}
            >
              {other}
            </SecondaryButton>
          )
        )}
      </Amount> */}

    {/* {predefinedValue === '' && (
        <InputField
          type="number"
          name="custom_amount"
          label="Amount"
          placeholder="Enter amount of dollars"
          inputOptions={{ register, watch, errors, required: true }}
        />
      )}
      <Pay>
        <div className="payment-button-container" />
      </Pay> */}

    {/* <Text>{payByCard}</Text>
      <InputFields>
        <InputField
          type="card"
          name="card_number"
          label={cardNumber}
          placeholder={cardNumberPlaceholder}
          inputOptions={{ register, watch, errors, required: true }}
        />
        <InputField
          type="email"
          name="email"
          label={emailPlaceholder}
          placeholder="example@site.com"
          inputOptions={{ register, watch, errors, required: true }}
        />
        <input type="hidden" name="payment_system" value="card" />
        <input type="hidden" name="merchant_id" value="1504229" />
        <input type="hidden" name="amount" value={amount} />
        <input
          type="hidden"
          name="currency"
          value={CURRENCY_MAP[pageLocale].bankId}
        />
      </InputFields> */}

    <SubmitButtons>
      <PrimaryButton
        href="https://pay.fondy.eu/s/MrKe6T0dey4HVV"
        rel="noopener noreferrer"
        target="_blank"
        as="a"
      >
        {translations?.donate}
      </PrimaryButton>
      <SecondaryButton as="a" href="#footer">
        {translations?.otherMethods}
      </SecondaryButton>
    </SubmitButtons>
    {/* </Form> */}

    {/* <Partners>
      <p>Our main partners</p>
      <StaticImage
        width={85}
        height={28}
        src="../../../assets/partners/google.png"
        alt="Google"
        as={Partner}
      />
      <StaticImage
        width={94}
        height={28}
        src="../../../assets/partners/facebook.png"
        alt="Facebook"
        as={Partner}
      />
      <StaticImage
        width={92}
        height={28}
        src="../../../assets/partners/springfield-armory.png"
        alt="Springfield Armory"
        as={Partner}
      />
      <StaticImage
        width={59}
        height={28}
        src="../../../assets/partners/daniel-defense.png"
        alt="Daniel Defense"
        as={Partner}
      />
    </Partners> */}
  </Wrapper>
);

export default PaymentForm;
