import { graphql } from 'gatsby';
import { StructuredText } from 'react-datocms';

import Highlights from 'templates/HomePage/Highlights';
import MainProjects from 'templates/HomePage/MainProjects';
import { ScrollableContainer } from 'components/UI/Containers';
import OurTeam from 'components/OurTeam';
import RecentPhotoReports from 'templates/HomePage/RecentPhotoReports';

import { Navigator } from 'components/Layout/Navigator';
import { PageWrapper } from 'components/Layout/PageWrapper';

import { MoreInfoLink, Section } from 'styles/elements';
import { HeadingMedium } from 'components/UI/Headings';
import Hero from './Hero';

const HomepageTemplate = ({
  data: {
    datoCmsHomepage: {
      seo,
      highlights,
      mainProjectsTitle,
      ourTeamTitle,
      recentPhotoReportsTitle,
      morePhotosTitle,
    },
    datoCmsAboutPage: aboutPage,
    datoCmsHowToRequest: howToRequest,
    allDatoCmsProject: projects,
    allDatoCmsTeammate: teammates,
    allDatoCmsPhotoReport: reports,
    datoCmsMiscTextString: translations,
    datoCmsDonation: donation,
  },
  pageContext,
}) => {
  const { readMoreAboutUs } = translations;

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.seoImage?.seoImageUrl}
    >
      <Section>
        <Hero donation={donation} translations={translations} />
      </Section>

      <Section>
        <Highlights highlights={highlights[0]} />
      </Section>

      <Section>
        <MainProjects
          heading={mainProjectsTitle}
          projects={projects}
          request={howToRequest}
          translations={translations}
        />
      </Section>

      <Section>
        <Section as="div">
          <HeadingMedium isCentered as="h3">
            {ourTeamTitle}
          </HeadingMedium>
          <ScrollableContainer>
            <OurTeam teammates={teammates} />
          </ScrollableContainer>
        </Section>
        <MoreInfoLink>
          <Navigator as={Navigator} recordId={aboutPage.id}>
            {readMoreAboutUs}
          </Navigator>
        </MoreInfoLink>
      </Section>

      <Section>
        <Section as="div">
          <RecentPhotoReports
            heading={recentPhotoReportsTitle}
            reports={reports}
          />
        </Section>
        <MoreInfoLink>
          <StructuredText data={morePhotosTitle} />
        </MoreInfoLink>
      </Section>
    </PageWrapper>
  );
};

export default HomepageTemplate;

export const query = graphql`
  query HomepageQuery($locale: String!) {
    datoCmsHomepage(locale: { eq: $locale }) {
      locale
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
      title
      highlights {
        title
        backgroundColor {
          hex
        }
        cards {
          id: originalId
          name
          description {
            value
          }
          image {
            url
          }
          gif {
            url
          }
        }
      }
      mainProjectsTitle
      ourTeamTitle
      recentPhotoReportsTitle
      morePhotosTitle {
        value
      }
    }
    datoCmsDonation(locale: { eq: $locale }) {
      id: originalId
      locale
      title
      subTitle
    }
    datoCmsAboutPage(locale: { eq: $locale }) {
      id: originalId
    }
    datoCmsHowToRequest(locale: { eq: $locale }) {
      title
      instruction {
        value
      }
      applyRequestButton
      applyRequestForm
    }
    allDatoCmsProject(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          current
          target
          title
          currency
          projectStatus
          linkToFacebookPost
          description {
            value
          }
          gallery {
            url
          }
        }
      }
    }
    allDatoCmsTeammate(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          fullName
          role
          photo {
            url
            alt
            width
            height
            originalId
            blurhash
          }
        }
      }
    }
    allDatoCmsPhotoReport(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          image {
            alt
            blurhash
            width
            url
            height
          }
          name
          info
          link
        }
      }
    }
    datoCmsMiscTextString(locale: { eq: $locale }) {
      locale
      readDetails
      viewResults
      donate
      readMoreAboutUs
      otherMethods
    }
  }
`;
