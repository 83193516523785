import HeroVideo from 'assets/hero.mp4';
import PaymentForm from 'components/PaymentForm';

import { Wrapper, Video } from './styles';

const Hero = ({ translations, donation }) => {
  return (
    <Wrapper>
      <Video loop muted autoPlay playsInline>
        <source src={HeroVideo} type="video/mp4" />
      </Video>

      <PaymentForm
        title={donation?.title}
        subTitle={donation?.subTitle}
        translations={translations}
      />
    </Wrapper>
  );
};

export default Hero;
