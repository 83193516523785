import styled from 'styled-components';

import { PrimaryButton } from 'components/UI/Buttons';

import { easeOutTiming } from 'styles/animation';
import { media } from 'styles/mixins';

const Form = styled.form`
  transition: all ${easeOutTiming};
  border: var(--border) solid var(--border-color-10);
  background-color: var(--form-background-color);
  padding: 1.4375rem 1.4375rem 31px 1.4375rem;
  border-radius: var(--box-radius);
  width: var(--form-width);
  display: grid;

  ${media.mobile`
    width: auto;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  `}

  h2,
  h3 {
    margin-bottom: 0.75rem;

    ${media.mobile`
      font-size: 1.5rem;
    `}
  }

  p {
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
  }

  ${PrimaryButton} {
    margin-top: 1.5rem;
    min-width: 11.25rem;

    ${media.mobile`
      min-width: 100%;
      min-height: 2.75rem;
    `}
  }
`;

const InputFields = styled.div`
  row-gap: 0.375rem;
  display: grid;
`;

const SubmitButtons = styled.div`
  grid-auto-flow: column;
  width: fit-content;
  align-items: end;
  column-gap: 1rem;
  display: grid;

  ${media.mobile`
    width: auto;

    > * {
      width: auto;
    }
  `}
`;

export { Form, InputFields, SubmitButtons };
