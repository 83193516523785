import { HeadingMedium } from 'components/UI/Headings';
import Photo from 'components/Photo';

import { Container, PhotoReport } from './styles';

const RecentPhotoReports = ({ heading, reports }) => (
  <>
    <HeadingMedium isCentered as="h3">
      {heading}
    </HeadingMedium>
    <Container>
      {reports.edges.map((report, index) => {
        const {
          link,
          name,
          info,
          image: { url },
        } = report.node;

        return (
          <li key={index}>
            <a href={link || null} rel="noopener noreferrer" target="_blank">
              <PhotoReport
                src={url}
                title={name}
                description={info}
                as={Photo}
              />
            </a>
          </li>
        );
      })}
    </Container>
  </>
);

export default RecentPhotoReports;
