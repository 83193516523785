import styled from 'styled-components';

import { Card } from 'templates/HomePage/MainProjects/styles';

import { easeOutTiming } from 'styles/animation';

const Wrapper = styled(Card)`
  transition: background-color ${easeOutTiming};
  background-color: var(--primary-color-06);
  border-color: var(--primary-color-20);
  max-width: 17.875rem;

  &:hover {
    background-color: var(--primary-color-10);
  }

  ol {
    padding-left: 1rem;
    margin: 1rem 0 2.125rem;
    list-style-type: decimal;

    li {
      transition: color ${easeOutTiming};
      color: var(--secondary-text-color);
      font-size: 0.875rem;

      a {
        text-decoration: underline;
        color: var(--primary-color);
      }
    }
  }
`;

const Buttons = styled.div`
  align-items: end;
  display: grid;
`;

export { Wrapper, Buttons };
